import React, { useState, useEffect } from 'react';
import './Banner_V2.css';

function Banner_V2({ imageUrl, title, description }) {
  return (
    imageUrl && title && (
      <div className="cmp-banners">
        <img
          className="cmp-banner__imgs"
          src={imageUrl}
          alt={title|| 'Banner'}
        />
        <h1 className="cmp-banner__titles">{title}</h1>
        <div className="cmp-banner__descriptions">
        <p >{description}</p>
        </div>
      </div>
    )
  );
}

export default Banner_V2;
