import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import HomeFooter from '../HomeFooter/HomeFooter';
import Image_Left from '../contents/Reuse_Components/Global/Image_Left/Image_Left';
import Service_Details from '../contents/Reuse_Components/Global/Service_Details/Service_Details';
import maskgroup1 from '../contents/Reuse_Components/Global/Service_Details/Service_Details_Assests/Mask Group 245.png';
import maskgroup2 from '../contents/Reuse_Components/Global/Service_Details/Service_Details_Assests/Mask Group 246.png';
import maskgroup3 from '../contents/Reuse_Components/Global/Service_Details/Service_Details_Assests/Mask Group 250.png';
import maskgroup4 from '../contents/Reuse_Components/Global/Service_Details/Service_Details_Assests/Mask Group 248.png';
import maskgroup5 from '../contents/Reuse_Components/Global/Service_Details/Service_Details_Assests/Mask Group 247.png';
import maskgroup6 from '../contents/Reuse_Components/Global/Service_Details/Service_Details_Assests/Mask Group 251.png';
import Image_Section from '../contents/Reuse_Components/Global/Image_Section/Image_Section';
import Contact_Us from '../contents/Reuse_Components/Global/Contact_Us/Contact_Us';
import imagehfcallion from '../contents/Reuse_Components/Global/Contact_Us/Contact_Us_Assests/hfcallicon.png';
import imagehflocicon from '../contents/Reuse_Components/Global/Contact_Us/Contact_Us_Assests/hflocicon.png';
import imagehfmssgicon from '../contents/Reuse_Components/Global/Contact_Us/Contact_Us_Assests/hfmssgicon.png';
import Contact_Us_V2 from '../contents/Reuse_Components/Global/Contact_Us/Contact_Us_V2';
import Banner_V2 from '../contents/Reuse_Components/Global/Banner/Banner_V2';

const CommerceCloud = () => {
  const [bannerSection, setBannerSection] = useState(null);
  const [secondaryImageData, setSecondaryImageData] = useState(null);
  const [imageSectionData, setImageSectionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const services = [
    {
      image: maskgroup1,
      title: 'Consulting & Strategy',
      description: 'Define and refine your e-commerce strategy with our experts. We analyze your target audience and industry landscape to craft a strategic roadmap.',
    },
    {
      image: maskgroup2,
      title: 'Custom Development & Integration',
      description: 'Elevate your e-commerce capabilities with tailored development and seamless integrations with CRM, ERP, and other systems.',
    },
    {
      image: maskgroup3,
      title: 'Migration to Adobe Commerce',
      description: 'Move to Adobe Commerce with confidence. We ensure a secure and optimized migration process from other platforms.',
    },
    {
      image: maskgroup4,
      title: 'Managed Services & Support',
      description: 'Get ongoing support to maintain, monitor, and update your Adobe Commerce store for a seamless experience.',
    },
    {
      image: maskgroup5,
      title: 'Commerce Analytics & Insights',
      description: 'Leverage advanced analytics to understand user behavior and optimize performance, driving higher satisfaction and conversions.',
    },
    {
      image: maskgroup6,
      title: 'Existing Support',
      description: 'Our 24/7 support team monitors and swiftly resolves issues on your Adobe Commerce site to ensure smooth operations.',
    },
  ];

  const contactUsData = {
    contactDetails: [
      { icon: imagehfcallion, text: '+61 411 048 090' },
      { icon: imagehfmssgicon, text: 'sales@dluxtech.com' },
      { icon: imagehflocicon, text: '28 Beacon Drive, Schofields NSW 2762' },
    ],
    formAction: "https://forms.zohopublic.in/dluxtech/form/ContactUs/formperma/...",
    formFields: [
      { type: 'text', name: 'Name_First', placeholder: 'First Name', required: true, maxLength: 255 },
      { type: 'text', name: 'Name_Last', placeholder: 'Last Name', required: true, maxLength: 255 },
      { type: 'email', name: 'Email', placeholder: 'E-Mail', required: true, maxLength: 255 },
      { type: 'tel', name: 'PhoneNumber_countrycode', placeholder: 'Phone Number', required: true, maxLength: 20, pattern: '[0-9]*' },
      { type: 'textarea', name: 'MultiLine', placeholder: 'How Can We Help?', required: true, maxLength: 65535 },
    ],
  };

  const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
  const query = `
    query {
      platformCommerceCloud(id: "3nTMVnqCMpAp2Vf1n20pkh") {
        commerceCloudBannerSection {
          url
          description
          title
        }
      }
      platformCommerceCloudCollection {
        items {
          commerceCloudSecondaryImage {
            url
            description
          }
          commerceCloudImageSectionCollection {
            items {
              url
            }
          }
        }
      }
    }
  `;

  useEffect(() => {
    const fetchCommerceCloudData = async () => {
      try {
        const response = await fetch('https://graphql.contentful.com/content/v1/spaces/pj0maraabon4/environments/production', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ query }),
        });

        if (!response.ok) {
          const responseBody = await response.text();
          console.error('Failed to fetch:', response.status, responseBody);
          throw new Error(`Failed to fetch data: ${response.status}`);
        }

        const result = await response.json();
        const bannerData = result?.data?.platformCommerceCloud?.commerceCloudBannerSection;
        const secondaryImageData = result?.data?.platformCommerceCloudCollection?.items[0]?.commerceCloudSecondaryImage;
        const imageSectionData = result?.data?.platformCommerceCloudCollection?.items[0]?.commerceCloudImageSectionCollection?.items;

        setBannerSection(bannerData);
        setSecondaryImageData(secondaryImageData);
        setImageSectionData(imageSectionData);

      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCommerceCloudData();
  }, [accessToken]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="commerce-cloud-container">
      {/* Banner Section */}
      {bannerSection ? (
        <Banner_V2
          imageUrl={bannerSection.url}
          title={bannerSection.title}  
         description={bannerSection.description}
        />
      ) : (
        <div>No banner data available.</div>
      )}

      <div className="form12" style={{ height: "0px" }}>
        <form
          id="form3"
          className="form3"
          action="https://forms.zohopublic.in/dluxtech/form/BannerSectionForm/formperma/XUwElVETXNP5O6B9qy946vzmYmC-yYrKPxJ2PGHV1K4/htmlRecords/submit"
          method="POST"
          acceptCharset="UTF-8"
          encType="multipart/form-data"
        >
          <label htmlFor="name">Name:</label>
          <input type="text" name="SingleLine" id="name1" maxLength="255" required />
          <label htmlFor="email">Email:</label>
          <input type="email" name="Email" id="email1" maxLength="255" required />
          <label htmlFor="phone">Phone Number:</label>
          <input type="tel" name="PhoneNumber_countrycode" id="phone1" maxLength="20" required />
          <label htmlFor="requirement">Requirement:</label>
          <input type="text" name="names" id="names" maxLength="1000" />

          <button type="submit"><em>Submit</em></button>
        </form>
      </div>

      <Image_Left imageUrl={secondaryImageData?.url} description={secondaryImageData?.description} />

      {/* Description Text */}
      <div className="description-text">
        <p>
          From consulting and implementation to cartridge development and SiteGenesis-to-SFRA migration, we provide end-to-end solutions tailored to your needs. Whether you're launching, scaling, or replatforming, our expertise and rapid deployment accelerators ensure cost-effective, unified commerce solutions for optimized customer journeys.
        </p>
        <h3>Take the next step towards smarter, Customer-Centric Decisions.</h3>
      </div>

      {/* Service Details */}
      <Service_Details services={services} />

      {/* Image Section */}
      <Image_Section
        imageUrls={imageSectionData.map((item) => item.url)}
        altTexts={imageSectionData.map((_, index) => `Image Section ${index + 1}`)}
      />

      {/* Contact Form */}
      <Contact_Us_V2 />
      <Contact_Us
        contactDetails={contactUsData.contactDetails}
        formAction={contactUsData.formAction}
        formFields={contactUsData.formFields}
      />

      {/* Navbar and Footer */}
      <Navbar />
      <HomeFooter />
    </div>
  );
};

export default CommerceCloud;
